

function About() {
    return (
      <div className="About">
        <h1>Hi, my name is Shreyas! I am an aspiring developer, and currently learning my way through ReactJS.</h1>
          
          
      </div>
    );
  }
  
  export default About;
  