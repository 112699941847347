

function Contact() {
    return (
      <div className="Contact">
          
      </div>
    );
  }
  
  export default Contact;
  